import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MoonLoader } from "react-spinners";

const HomePage = React.lazy(() => import("./pages/HomePage"));
const ProcessOverview = React.lazy(() => import("./pages/ProcessOverview"));
const LanguageModel = React.lazy(() => import("./pages/LanguageModel"));
const DemoInstall = React.lazy(() => import("./pages/DemoInstall"));
const Customization = React.lazy(() => import("./pages/Customization"));
//const ApIIntegration = React.lazy(() => import("./pages/ApIIntegration"));
const WhatsAppIntegration = React.lazy(() =>import("./pages/WhatsAppIntegration"));
const Management = React.lazy(() => import("./pages/Management"));
const Upgrading = React.lazy(() => import("./pages/Upgrading"));
const BuyLicense = React.lazy(() => import("./pages/BuyLicense"));
const ContactUs = React.lazy(() => import("./pages/ContactUs"));
const SigninPage = React.lazy(() => import("./pages/SigninPage"));
const DemoAdd = React.lazy(() => import("./pages/DemoAdd"));
const DemoMulti = React.lazy(() => import("./pages/DemoMulti"));
const GotoChat = React.lazy(() => import("./pages/GotoChat"));
const ChatTemplate = React.lazy(() => import("./pages/ChatTemplate"));
const ConfigureManage = React.lazy(() => import("./pages/ConfigureManage"));
const Createkb = React.lazy(() => import("./pages/Createkb"));
const Settings = React.lazy(() => import("./pages/Settings"));
const ChangePassword = React.lazy(() => import("./pages/ChangePassword"));
const DemoContent = React.lazy(() => import("./pages/DemoContent"));
const ManageUsers = React.lazy(() => import("./pages/ManageUsers"));
const KnowledgeBases = React.lazy(() => import("./pages/KnowledgeBases"));

const ProjectRoutes = () => {
  return (
    <React.Suspense
      fallback={
        <>
          <div
            style={{ backgroundColor: "#BDE9C9" }}
            className="w-full h-screen flex items-center justify-center"
          >
            <MoonLoader color="#008000" />
          </div>
        </>
      }
    >
      <Router>
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/" element={<HomePage />} />
          {/* <Route path="*" element={<NotFound />} /> */}
          <Route path="/ProcessOverview" element={<ProcessOverview />} />
          <Route path="/LanguageModel" element={<LanguageModel />} />
          <Route path="/DemoInstall" element={<DemoInstall />} />
          <Route path="/Customization" element={<Customization />} />
          {/* <Route path="/ApIIntegration" element={<ApIIntegration />} /> */}
          <Route path="/WhatsAppIntegration"element={<WhatsAppIntegration />}/>
          <Route path="/Management" element={<Management />} />
          <Route path="/Upgrading" element={<Upgrading />} />
          <Route path="/BuyLicense" element={<BuyLicense />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/SigninPage" element={<SigninPage />} />
          <Route path="/DemoAdd" element={<DemoAdd />} />
          <Route path="/DemoMulti" element={<DemoMulti />} />
          <Route path="/GotoChat" element={<GotoChat />} />
          <Route path="/ChatTemplate" element={<ChatTemplate />} />
          <Route path="/ConfigureManage" element={<ConfigureManage />} />
          <Route path="/Createkb" element={<Createkb />} />
          <Route path="/Settings" element={<Settings />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />
          <Route path="/DemoContent" element={<DemoContent />} />
          <Route path="/ManageUsers" element={<ManageUsers />} />      
          <Route path="/KnowledgeBases" element={<KnowledgeBases />} />   
        </Routes>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;
